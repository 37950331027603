<template>
<div>
  <Form label-position="top">
    <template v-for="(item, index) in field">
      <FormItem :label="item.paper_form_name" v-if="item.paper_form_type === 'upload'" :key="index">
        <Row :gutter="20">
          <Col span="4" style="margin-top: 15px;position: relative" v-for="(img, i) in info[item.paper_form_field].split('||')" :key="`info[item.paper_form_field]` + i" class="img-block">

            <div style="background-color: #ededed;display: flex;justify-content: center;align-items: center;height: 400px;">
              <img :src="img" alt="" class="img" @click="viewImg(img)">
            </div>
          </Col>
        </Row>
      </FormItem>

      <FormItem :label="item.paper_form_name" v-else-if="item.paper_form_type === 'textarea'" :key="index">
        <Input type="textarea" :autosize="true" v-model="info[item.paper_form_field]" readonly></Input>
      </FormItem>

      <FormItem :label="item.paper_form_name" v-else :key="index">
        <Input v-model="info[item.paper_form_field]" readonly></Input>
      </FormItem>

    </template>

  </Form>

  <Modal v-model="viewImgModal" fullscreen title="查看图片" footer-hide>
    <div class="img_box">
      <img :src="imgPath" alt="" @click="rotate()" ref="rotate2">
    </div>
  </Modal>
</div>
</template>

<script>
import api from '../../api/xiaochengxu';
export default {
  name: "PaperSubmitDetail",
  data: function(){
    return {
      paper_id: '',
      table_id: '',
      info: {},
      field: [],
      viewImgModal: false,
      imgPath: '',
      deg: 0,
    }
  },
  methods: {
    viewImg: function(path){
      this.imgPath = path;
      this.viewImgModal = true;
    },
    rotate: function(){
      this.deg += 90;
      if (this.deg >= 360) {
        this.deg = 0
      }
      this.$refs.rotate2.style.transform = `rotate(${this.deg}deg)`
    },
  },
  mounted() {
    this.paper_id = this.$route.query.paper_id;
    this.table_id =  this.$route.query.paper_table_id;
    this.$emit('lastPage', '/xiaochengxu/paper/submit?id=' + this.paper_id);
    this.$http.get(api.paperSubmitDetail, {params: {paper_id: this.paper_id, table_id: this.table_id}}).then(response => {
      if(response.data.success) {
        this.info = response.data.res.table;
        this.field = response.data.res.form;
      } else {
        this.$Message.error(response.data.err);
        console.log(response);
      }
    })
  },
  destroyed() {
    this.$emit('lastPage', '')
  }
}
</script>

<style scoped>
.img_box {
  width: 100%;
  height: 100%;
  position: relative;
}

.img_box img {
  max-width: 150%;
  max-height: 95%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}
.img {
  max-width: 100%;
  max-height: 400px;
}
</style>